import { conform, useForm, type Submission } from '@conform-to/react';
import { getFieldsetConstraint, parse } from '@conform-to/zod';
import { json, type ActionFunctionArgs } from '@remix-run/node';
import { Form, useActionData, useSearchParams } from '@remix-run/react';
import { AuthenticityTokenInput } from 'remix-utils/csrf/react';
import { HoneypotInputs } from 'remix-utils/honeypot/react';
import { z } from 'zod';

import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx';
import { ErrorList, Field } from '#app/components/forms.tsx';
import { StatusButton } from '#app/components/ui/status-button.tsx';
import { handleVerification as handleChangeEmailVerification } from '#app/routes/settings+/profile.change-email.tsx';
import { twoFAVerificationType } from '#app/routes/settings+/profile.two-factor.tsx';
import { type twoFAVerifyVerificationType } from '#app/routes/settings+/profile.two-factor.verify.tsx';
import { requireUserId } from '#app/utils/auth.server.tsx';
import { validateCSRF } from '#app/utils/csrf.server.ts';
import { prisma } from '#app/utils/db.server.ts';
import { checkHoneypot } from '#app/utils/honeypot.server.ts';
import { getDomainUrl, getEzLogo, useIsPending } from '#app/utils/misc.tsx';
import { redirectWithToast } from '#app/utils/toast.server.ts';
import { generateTOTP, verifyTOTP } from '#app/utils/totp.server.ts';

import {
	handleVerification as handleLoginTwoFactorVerification,
	shouldRequestTwoFA,
} from './login.tsx';
import { handleVerification as handleOnboardingVerification } from './onboarding.tsx';
import { handleVerification as handleResetPasswordVerification } from './reset-password.tsx';

export const codeQueryParam = 'code';
export const targetQueryParam = 'target';
export const typeQueryParam = 'type';
export const redirectToQueryParam = 'redirectTo';
const types = [
	'onboarding',
	'reset-password',
	'new-password',
	'change-email',
	'2fa',
] as const;
const VerificationTypeSchema = z.enum(types);
export type VerificationTypes = z.infer<typeof VerificationTypeSchema>;

const VerifySchema = z.object({
	[codeQueryParam]: z.string().min(6).max(6),
	[typeQueryParam]: VerificationTypeSchema,
	[targetQueryParam]: z.string(),
	[redirectToQueryParam]: z.string().optional(),
});

export async function action({ request }: ActionFunctionArgs) {
	const formData = await request.formData();
	checkHoneypot(formData);
	await validateCSRF(formData, request.headers);
	return validateRequest(request, formData);
}

export function getRedirectToUrl({
	request,
	type,
	target,
	redirectTo,
}: {
	request: Request;
	type: VerificationTypes;
	target: string;
	redirectTo?: string;
}) {
	const redirectToUrl = new URL(`${getDomainUrl(request)}/verify`);
	redirectToUrl.searchParams.set(typeQueryParam, type);
	redirectToUrl.searchParams.set(targetQueryParam, target);
	if (redirectTo) {
		redirectToUrl.searchParams.set(redirectToQueryParam, redirectTo);
	}
	return redirectToUrl;
}

export async function requireRecentVerification(request: Request) {
	const userId = await requireUserId(request);
	const shouldReverify = await shouldRequestTwoFA(request);
	if (shouldReverify) {
		const reqUrl = new URL(request.url);
		const redirectUrl = getRedirectToUrl({
			request,
			target: userId,
			type: twoFAVerificationType,
			redirectTo: reqUrl.pathname + reqUrl.search,
		});
		throw await redirectWithToast(redirectUrl.toString(), {
			title: 'Please Reverify',
			description: 'Please reverify your account before proceeding',
		});
	}
}

export async function prepareVerification({
	period,
	request,
	type,
	target,
}: {
	period: number;
	request: Request;
	type: VerificationTypes;
	target: string;
}) {
	const verifyUrl = getRedirectToUrl({ request, type, target });
	const redirectTo = new URL(verifyUrl.toString());

	const { otp, ...verificationConfig } = generateTOTP({
		algorithm: 'SHA256',
		// Leaving off 0 and O on purpose to avoid confusing users.
		charSet: 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789',
		period,
	});
	const verificationData = {
		type,
		target,
		...verificationConfig,
		expiresAt: new Date(Date.now() + verificationConfig.period * 1000),
	};
	await prisma.verification.upsert({
		where: { target_type: { target, type } },
		create: verificationData,
		update: verificationData,
	});

	// add the otp to the url we'll email the user.
	verifyUrl.searchParams.set(codeQueryParam, otp);

	return { otp, redirectTo, verifyUrl };
}

export type VerifyFunctionArgs = {
	request: Request;
	submission: Submission<z.infer<typeof VerifySchema>>;
	body: FormData | URLSearchParams;
};

export async function isCodeValid({
	code,
	type,
	target,
}: {
	code: string;
	type: VerificationTypes | typeof twoFAVerifyVerificationType;
	target: string;
}) {
	const verification = await prisma.verification.findUnique({
		where: {
			target_type: { target, type },
			OR: [{ expiresAt: { gt: new Date() } }, { expiresAt: null }],
		},
		select: { algorithm: true, secret: true, period: true, charSet: true },
	});
	if (!verification) return false;
	const result = verifyTOTP({
		otp: code,
		...verification,
		charSet: verification.charSet || undefined,
	});
	return result;
}

async function validateRequest(
	request: Request,
	body: URLSearchParams | FormData,
) {
	const submission = await parse(body, {
		schema: VerifySchema.superRefine(async (data, ctx) => {
			const codeIsValid = await isCodeValid({
				code: data[codeQueryParam],
				type: data[typeQueryParam],
				target: data[targetQueryParam],
			});
			if (!codeIsValid) {
				ctx.addIssue({
					path: ['code'],
					code: z.ZodIssueCode.custom,
					message: `Invalid code`,
				});
				return;
			}
		}),
		async: true,
	});

	if (submission.intent !== 'submit') {
		return json({ status: 'idle', submission } as const);
	}
	if (!submission.value) {
		return json({ status: 'error', submission } as const, { status: 400 });
	}

	const { value: submissionValue } = submission;

	async function deleteVerification() {
		await prisma.verification.delete({
			where: {
				target_type: {
					type: submissionValue[typeQueryParam],
					target: submissionValue[targetQueryParam],
				},
			},
		});
	}

	switch (submissionValue[typeQueryParam]) {
		case 'reset-password': {
			await deleteVerification();
			return handleResetPasswordVerification({ request, body, submission });
		}
		case 'new-password': {
			await deleteVerification();
			return handleResetPasswordVerification({ request, body, submission });
		}
		case 'onboarding': {
			await deleteVerification();
			return handleOnboardingVerification({ request, body, submission });
		}
		case 'change-email': {
			await deleteVerification();
			return handleChangeEmailVerification({ request, body, submission });
		}
		case '2fa': {
			return handleLoginTwoFactorVerification({ request, body, submission });
		}
	}
}

export default function VerifyRoute() {
	const [searchParams] = useSearchParams();
	const isPending = useIsPending();
	const actionData = useActionData<typeof action>();
	const parsedType = VerificationTypeSchema.safeParse(
		searchParams.get(typeQueryParam),
	);
	const type = parsedType.success ? parsedType.data : null;

	const checkEmail = (
		<>
			<h1 className="pl-8 text-h5" style={{ marginTop: '-1.5em' }}>
				Überprüfe deine <br />
				Email
			</h1>
			<div className="mx-auto mt-4 min-w-[368px] max-w-sm">
				Wir haben dir einen Code zugeschickt um die Email Adresse zu bestätigen.
			</div>
		</>
	);

	const newPassword = (
		<>
			<h1 className="pl-8 text-h5" style={{ marginTop: '-1.5em' }}>
				Neues Passwort <br />
				notwendig
			</h1>
			<div className="mx-auto mt-4 min-w-[368px] max-w-sm text-sm">
				Um den MeinTraktor HändlerLogin weiter nutzen zu können ist es notwendig
				deine E-Mail Adresse zu bestätigen und ein neues Passwort zu vergeben.{' '}
				<br />
				Zur Verifizierung der E-Mail Adresse haben wir dir einen Code geschickt
				der hier eingegeben werden muss!
			</div>
		</>
	);

	const headings: Record<VerificationTypes, React.ReactNode> = {
		onboarding: checkEmail,
		'new-password': newPassword,
		'reset-password': checkEmail,
		'change-email': checkEmail,
		'2fa': (
			<>
				<h1 className="pl-8 text-h5" style={{ marginTop: '-1.5em' }}>
					2-Faktor <br />
					Authentifizierung
				</h1>
				<div className="mx-auto mt-4 min-w-[368px] max-w-sm">
					Bitte gib den Code aus deiner 2FA App ein.
				</div>
			</>
		),
	};

	const [form, fields] = useForm({
		id: 'verify-form',
		constraint: getFieldsetConstraint(VerifySchema),
		lastSubmission: actionData?.submission,
		onValidate({ formData }) {
			return parse(formData, { schema: VerifySchema });
		},
		defaultValue: {
			code: searchParams.get(codeQueryParam) ?? '',
			type,
			target: searchParams.get(targetQueryParam) ?? '',
			redirectTo: searchParams.get(redirectToQueryParam) ?? '',
		},
	});

	return (
		<div className="flex min-h-screen flex-col justify-center bg-gray-100 pb-32 pt-20">
			<div className="mx-auto w-full max-w-md rounded border bg-white py-4">
				<div className="mb-4 flex flex-col gap-3">
					<img
						src={getEzLogo()}
						alt="MeinTraktor Logo"
						className="ml-auto w-[60%] pr-8"
					/>
					{type ? headings[type] : 'Invalid Verification Type'}
				</div>

				<div>
					<ErrorList errors={form.errors} id={form.errorId} />
				</div>
				<div className="mx-auto mt-4 min-w-[368px] max-w-sm">
					<Form method="POST" {...form.props} className="flex-1">
						<AuthenticityTokenInput />
						<HoneypotInputs />
						<Field
							labelProps={{
								htmlFor: fields[codeQueryParam].id,
								children: 'Code',
							}}
							inputProps={{
								...conform.input(fields[codeQueryParam]),
								autoComplete: 'one-time-code',
							}}
							errors={fields[codeQueryParam].errors}
						/>
						<input
							{...conform.input(fields[typeQueryParam], { type: 'hidden' })}
						/>
						<input
							{...conform.input(fields[targetQueryParam], { type: 'hidden' })}
						/>
						<input
							{...conform.input(fields[redirectToQueryParam], {
								type: 'hidden',
							})}
						/>
						<StatusButton
							className="w-full"
							status={isPending ? 'pending' : actionData?.status ?? 'idle'}
							type="submit"
							disabled={isPending}
						>
							Absenden
						</StatusButton>
					</Form>
				</div>
			</div>
		</div>
	);
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />;
}
